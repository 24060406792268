<template>
  <div id="header">
    <div id="icon-navigation">
      <router-link to="/navigation">
        <div id="logo">
          <svg viewBox="-148 -73.8129 300 221.7" width="100%" height="100%">
            <path transform="scale(-1,1)"
              d="M10.9-72.9c30.5 3.9 55.2 26.4 62.7 56.9.7 3 1.3 10.3 1.4 16.1 0 17.8-3.4 27.8-18.3 52.9-6.2 10.5-23.7 40.6-49.6 85.2-3.1 5.4-6.1 9.7-6.6 9.7-.6 0-4.4-5.9-8.5-13.1-4.1-7.2-10.7-18.6-14.6-25.2-26-44.5-41-70.7-44-76.9-15.6-33-5.9-71.6 23.1-92.2 10.9-7.7 20.9-11.6 34.8-13.5 8.7-1.1 10.4-1.1 19.6.1zm-21.4 16.4c-22.3 4-41.9 22.7-46.4 44.3-2.3 11.1-1.1 24.3 3.2 34.4l1.6 3.8 8-0c4.4-0 8.2-.4 8.5-.9.3-.5-.6-3.5-2-6.7-7.1-16-4.3-34.1 7.1-46.3 8.3-8.9 18.3-13.1 31.1-13.1 17.5-0 31.4 8.7 38.7 24.3 3 6.6 3.5 7.1 9.9 10.3 8.6 4.3 9.8 4.3 9.8.1-.1-14.3-13-34.4-27.5-42.8-14.1-8.1-26-10.3-42-7.4zm-1.3 34.2c-9.5 4.5-15.3 15.5-13.7 26 .4 2.6 3.3 9.7 6.6 16.1 9.8 18.9 8.1 22.2-12.1 22.2-8.3 0-11 .3-11 1.3 0 1.8 22.4 40 23.7 40.4.6.2 2.6-2.2 4.4-5.4 1.8-3.2 11.1-19.3 20.7-35.8 9.7-16.5 18.2-32 18.9-34.5 3.6-11.6-1.6-24.9-11.9-30.1-8.5-4.3-16.7-4.4-25.6-.2zm61.5 28.9c-7.2 3.4-7.5 3.8-10 9.6-2.3 5.3-13 24.1-32.2 56.8-12.6 21.4-15.5 26.7-15.5 28.2 0 2.3 7.2 14.8 8.5 14.8.6-0 9.1-13.8 19-30.7 9.8-17 21.2-36.5 25.3-43.5 4-7 8.7-15.9 10.3-19.7 3.1-7.4 5-19.1 3.1-19.1-.6 0-4.4 1.6-8.5 3.6zM4.6-10c3.7 1.5 6.4 5.9 6.4 10.4 0 6-4.3 10.1-10.6 10.1-3.9 0-5-.5-7.5-3.3-5-5.6-3.6-13.3 3.1-16.7 3.2-1.7 5.5-1.8 8.6-.5z"
              fill="#000000" />
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style>
#header {
  position: absolute;
  right: calc(20px + (100% - 320px) * 0.51);
  top: 30px;
  margin: 0px 0px;
  width: 75px;
  display: flex;
  justify-content: right;
  padding: 0px;
  z-index: 2;
}

.navigation-icon {
  width: 75px;
  height: 75px;
}

#logo {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: white;
}
</style>