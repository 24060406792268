import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import AboutPage from './components/AboutPage.vue';
import Settings from './components/SettingsPage.vue';
import Navigation from './components/NavigationPage.vue';
import Play from './components/PlayPage.vue';
import Confirmation from './components/ConfirmationPage.vue';
import Leaderboard from './components/LeaderboardPage.vue';
import PrkenLand from './components/PrkenLandPage.vue';
import EditAvatar from './components/EditAvatarPage.vue';
import PrkenShop from './components/ShopPage.vue';
import BuyAvatar from './components/BuyAvatarPage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomePage
    },
    {
      path: '/play',
      name: 'Play',
      component: Play
    },
    {
      path: '/about',
      name: 'About',
      component: AboutPage
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings
    },
    {
      path: '/navigation',
      name: 'Navigation',
      component: Navigation
    },
    {
      path: '/confirmation',
      name: 'Confirmation',
      component: Confirmation
    },
    {
      path: '/leaderboard',
      name: 'Leaderboard',
      component: Leaderboard
    },
    {
      path: '/prkenland',
      name: 'Prkenland',
      component: PrkenLand
    },
    {
      path: '/edit-avatar',
      name: 'EditAvatar',
      component: EditAvatar
    },
    {
      path: '/shop',
      name: 'Shop',
      component: PrkenShop
    },
    {
      path: '/buy-avatar/:key',
      name: 'BuyAvatar',
      component: BuyAvatar,
      props: true
    }
  ]
});

export default router;
