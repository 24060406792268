import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

/**
 * Fetches avatars from the server.
 *
 * @description This function makes an asynchronous HTTP GET request to the '/api/shop-get-avatars' endpoint
 * to retrieve avatar data. If the request is successful, it returns the data from the response.
 * If an error occurs during the request, it logs the error to the console.
 *
 * @async
 * @function getAvatars
 * @returns {Promise<Object>} A promise that resolves to the avatar data.
 * @throws Will log an error message to the console if the request fails.
 */
export async function getAvatars() {
  try {
    const response = await axios.get('/api/shop-get-avatars');
    return response.data;
  } catch (error) {
    console.error('An error occurred in getAvatars:', error);
  }
}

/**
 * This file contains the function to fetch avatar data from the server.
 * 
 * @description Fetches avatar data from the server using the provided avatar ID.
 * @async
 * @param {string} avatarId - The ID of the avatar to fetch.
 * @returns {Promise<Object>} A promise that resolves to the avatar data.
 * @throws Will log an error message to the console if the request fails.
 */
export async function getAvatar(avatarId) {
  try {
    const response = await axios.get(`/api/shop-get-avatar/${avatarId}`);
    return response.data;
  } catch (error) {
    console.error('An error occurred in getAvatar:', error);
  }
}