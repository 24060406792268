<template>
  <LandingPage v-if="showLandingPage" @enterApp="enterApp"/>
  <Navigation />
  <router-view />
  <Footer />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import Footer from './components/FooterComponent.vue';
import LandingPage from './components/LandingPage.vue';
import Navigation from './components/NavigationComponent.vue';

// Create a ref for `showLandingPage`
const showLandingPage = ref(localStorage.getItem('showLandingPage') !== 'false');

// Access the Vuex store
const store = useStore();

// Fetch user when the component is created
onMounted(() => {
  store.dispatch('fetchUser');
  store.dispatch('fetchLastObservationTime');
});

watch(
  () => store.getters.remainingSeconds,
  (newValue) => {
    if (store.getters.timerRunning && newValue > 0) {
      const logo = document.getElementById('logo');
      const progress = (newValue / 120) * 100;
      logo.style.background = 'radial-gradient(closest-side, white 90%, transparent 90% 100%), conic-gradient(#ff6086 ' + progress + '%, white 0)';
    } else {
      const logo = document.getElementById('logo');
      logo.style.background = 'white';
    }
  }
);

// Method to handle entering the app and updating localStorage
const enterApp = () => {
  showLandingPage.value = false;
  localStorage.setItem('showLandingPage', 'false');
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Basic Body Styling */
body {
  font-family: "Quicksand", sans-serif;
  background-color: #FEF8F4;
  color: #333;
  line-height: 1.6;
}

/* App Container */
/* Center app div on screen */
#app {
  position: relative;
  margin: auto;
  min-width: 320px;
  max-width: 480px;
  min-height: 480px;
  /* outline: 1px solid black; */
}

.content-container {
  width: 270px;
  margin: 0 auto;
  text-align: left;
  padding-top: 140px;
}

/* Typographie */
.title {
  font-size: 24px;
  font-weight: 700;
}
</style>
