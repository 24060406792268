import axios from 'axios';
import { validateUserEntry } from './validator';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

/**
 * Fetches user data from server.
 * 
 * @description This function makes an asynchronous HTTP GET request to the '/api/query-user-data' endpoint
 * to retrieve user data. If the request is successful, it returns the data from the response.
 * If an error occurs during the request, it logs the error to the console.
 */
export async function getUserData() {
  try {
    const response = await axios.get('/api/query-user-data');
    const validation = await validateUserEntry(response.data);
    if(validation.valid) {
      return validation.data;
    } else {
      console.error('User data validation failed:', validation);
    }
  } catch (error) {
    console.error('An error occurred in getUserData:', error);
  }
}

/**
 * Fetches user coins from server.
 * 
 * @description This function makes an asynchronous HTTP GET request to the '/api/query-user-coins' endpoint
 * to retrieve user coins. If the request is successful, it returns the data from the response.
 * If an error occurs during the request, it logs the error to the console.
 */
export async function getUserCoins() {
  try {
    const response = await getUserData();
    console.log('coins response', response);
    return response.prken_coins;
  } catch (error) {
    console.error('An error occurred in getUserCoins:', error);
  }
}

/**
 * Get available avaters from user.
 */
export async function getUserAvatars() {
  try {
    const response = await getUserData();
    console.log('response', response.avatars);
    return response.avatars;
  } catch (error) {
    console.error('An error occurred in getUserAvatars:', error);
  }
}