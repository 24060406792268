<template>
  <div class="toggle-slider-container" @click="toggleSlider">
    <div class="toggle-slider-button">
    </div>
  </div>
  <div class="slider-container" v-if="showSlider">
    <VueSlider v-model="currentDay" tooltip="none" :min="minDay" :max="maxDay" :marks="dateMarks" :step="1" :width="240"
      @change="onSliderInput" />
  </div>
  <div id="mapPrkenLand"></div>
</template>

<script>
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Configure Axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

// Configure Leaflet icons
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
</script>

<script setup>
import { shallowRef, ref, onMounted, computed } from 'vue';
import VueSlider from "vue-3-slider-component";
import { getAvatars } from '../avatars/loader';

const map = shallowRef();
const observations = ref([]);
const circleLayerGroup = shallowRef(null);
const showSlider = ref(false);
const avatars = ref([]);

// Reactive references for the slider's min, max, and current value
const minDay = ref(0); // Start from 0 representing 5 days ago
const maxDay = ref(3); // End at 5 representing today
const currentDay = ref(2); // Default to today

// Current date
const today = new Date();
// Start from 5 days ago
const twoDaysAgo = new Date(today);
twoDaysAgo.setDate(today.getDate() - currentDay.value);

// Function to get the short day name
const getShortDayName = (date) => {
  const shortWeekDay = date.toLocaleDateString('en-US', { weekday: 'short' });
  return shortWeekDay.slice(0, 2);
}

// Function to generate date marks to label the slider with short weekday names
const generateDateMarks = () => {
  const marks = {};
  const date = new Date(twoDaysAgo);
  for (let i = 0; i <= maxDay.value; i++) {
    marks[i] = getShortDayName(date); // Get short day names (Mon, Tue, etc.)
    date.setDate(date.getDate() + 1);
  }
  return marks;
}

// Computed property to calculate the date marks
const dateMarks = computed(() => generateDateMarks());

// Function triggered when the slider is moved
const onSliderInput = (value) => {
  console.log("Slider Value:", value);
  const selectedDate = new Date(twoDaysAgo);
  selectedDate.setDate(twoDaysAgo.getDate() + value);

  queryObservationsOnDayN(value, selectedDate);
}

// Function to toggle the slider visibility
const toggleSlider = () => {
  showSlider.value = !showSlider.value;
}

const queryObservationsOnDayN = async (sliderValue, selectedDate) => {
  try {
    console.log(`Querying observations for day ${selectedDate}`);
    if(selectedDate.toDateString() > today.toDateString()){
      console.log("Selected date is in the future.");
      selectedDate.setDate(selectedDate.getDate() - 7);
    }
    const response = await axios.post('/api/all-observations-on-day-n', {
      day: selectedDate
    });
    observations.value = response.data;

    console.log(response.value);

    // Remove existing circles from map
    circleLayerGroup.value.clearLayers();

    // Get current zoom level of the map.
    const zoom = map.value.getZoom();
    const radius = -20 * zoom + 360;

    // Add observations to map
    observations.value.forEach(entry => {
      // Add green or red circle based on observation value
      const fillColor = entry.observationValue === 1 ? '#98ffc3' : '#ff6086';
      const coordinates = entry.geometry.coordinates;
      console.log(`Coordinates: Latitude = ${coordinates[1]}, Longitude = ${coordinates[0]}`);
      const circleOptions = {
        fillColor: fillColor,
        fillOpacity: 0.5,
        stroke: true,
        color: fillColor,
        radius: radius
      };
      L.circle([coordinates[1], coordinates[0]], circleOptions).addTo(circleLayerGroup.value);

      // Add avatar icon if we query the current day.
      if(today.toDateString() === selectedDate.toDateString()) {
        var avatarIcon = L.icon({
          iconUrl: avatars.value[entry.avatar].url,
          iconSize: [30, 30],
          iconAnchor: [15, 15],
          popupAnchor: [0, -15],
        });
        var avatarMarker = L.marker([coordinates[1], coordinates[0]], { icon: avatarIcon }).addTo(circleLayerGroup.value);

        // Add popup with timestamp
        // Calculate time difference and round to hours
        var ago = new Date(entry.timestamp);
        var now = new Date();
        var diff = now - ago;
        var minutes = Math.floor(diff / 60000); // minutes
        var hours = Math.floor(minutes / 60); // hours
        avatarMarker.bindPopup(`${entry.observationValue === 1 ? 'Free' : 'Taken'} ${hours === 0 ? '< 1' : hours} hours ago`);
      }
    });
  } catch (error) {
    console.error(error);
  }
};

const onLocationFound = (e) => {
  const radius = e.accuracy;
  console.log(radius);
};

const onMapZoomEnd = () => {
  const zoom = map.value.getZoom();
  console.log(`Current Zoom Level: ${zoom}`);

  // Adjust circle radii based on zoom level
  circleLayerGroup.value.getLayers().forEach(layer => {
    if (layer instanceof L.Circle) {
      if (zoom <= 17) {
        const newRadius = -20 * zoom + 360; // Adjust the scaling factor as needed
        layer.setRadius(newRadius);
      }
    }
  });
};

onMounted(async () => {
  map.value = L.map('mapPrkenLand', {
    zoomControl: false,
    center: [47.3769, 8.5417], // ZH coordinates
    zoom: 13
  });

  L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>'
  }).addTo(map.value);

  map.value.on('locationfound', onLocationFound);
  map.value.on('zoomend', onMapZoomEnd);
  map.value.locate({ watch: false, setView: true, maxZoom: 17 });

  avatars.value = await getAvatars();

  circleLayerGroup.value = L.layerGroup().addTo(map.value);
  queryObservationsOnDayN(5, new Date());
});
</script>


<style>
#mapPrkenLand {
  position: relative;
  top: 20px;
  z-index: 1;
  height: 89vh;
  min-height: 430px;
  width: 320px;
  border-radius: 25px;
  margin: 0 auto;
}

.slider-container {
  position: absolute;
  z-index: 2;
  background-color: #FEF8F4;
  border-radius: 10px;
  box-shadow: 3px 3px 5px gray;
  width: 300px;
  left: calc(50% - 150px);
  height: 60px;
  top: calc(75% - 30px);
}

.vue-slider {
  margin: 0 auto;
  margin-top: 10px;
}

.toggle-slider-container {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 30px;
  height: 30px;
  right: calc(41px + (100% - 320px) * 0.51);
  top: 115px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #33333373;
  box-shadow: 3px 3px 5px #333;
}

.toggle-slider-button {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 10px solid #333;
  border-bottom: 5px solid transparent;
  margin: 0 auto;
  margin-top: 9px;
  margin-left: 11px;
}

/* .leaflet-control-attribution {
  display: none;
} */
</style>