<template>
  <div class="content-container">
    <p class="title">prken. leaderboard</p>
    <p class="rank-info">Your rank is #{{ rank }}.</p>
    <div v-for="(entry, index) in leaderboard" :key="index" :class="['avatar']">
      <div class="leaderboard-entry">
        <div class="avatar-container">
          <img class="avatar-icon" :src="avatars[entry.avatar].url" alt="Avatar" />
          # {{ entry.rank }}
        </div>
        <div :class="'background element' + (index + 1)">
          <div class="observation-count">
            {{ entry.observations }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { getAvatars } from '../avatars/loader';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const leaderboard = ref([]);
const rank = ref(0);
const avatars = ref([]);

const queryLeaderboard = async () => {
  try {
    const response = await axios.get('/api/query-leaderboard');
    console.log(response.data);
    leaderboard.value = response.data;
    const userIndex = response.data.findIndex((entry) => entry.current_user === true);
    console.log(userIndex);
    rank.value = response.data[userIndex].rank;
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  avatars.value = await getAvatars();
  queryLeaderboard();
});
</script>

<style scoped>
.leaderboard-entry {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
}

.rank-info {
  font-size: 16px;
  /* font-weight: 500; */
  margin-bottom: 40px;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}

.avatar-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.background {
  width: 200px;
  height: 25px;
  /* center text */
  text-align: right;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 10px;
  background: rgb(71, 195, 146);
  background: linear-gradient(90deg, rgba(71, 195, 146, 1) 0%, rgba(255, 239, 166, 1) 30%, rgba(253, 119, 180, 1) 60%, rgba(182, 126, 214, 1) 85%, rgba(148, 193, 237, 1) 100%);
}

.element1 {
  width: 200px;
}

.element2 {
  width: 160px;
}

.element3 {
  width: 100px;
}

.observation-count {
  color: white;
  font-weight: 500;
  margin-right: 10px;
}
</style>